<template>
  <v-container grid-list-xl>
    <v-form ref="form" lazy-validation class="settings" v-model="valid">
      <v-layout row wrap>
        <v-layout>
          <v-flex>
            <view-title />
          </v-flex>

          <v-flex shrink fill-height>
            <v-btn
              :disabled="isConvertToMarketplaceDisabled"
              color="primary"
              @click="dialog = true"
            >
              Convert to Marketplace
            </v-btn>
          </v-flex>
        </v-layout>
      </v-layout>
      <v-flex>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card>
              <v-card-title primary-title>
                <div class="H5-Primary-Left">Business Unit Information</div>
              </v-card-title>
              <v-layout wrap class="ml-3">
                <v-flex xs5>
                  <v-text-field
                    label="Business Unit Name"
                    v-model="name"
                    :disabled="!isAdmin"
                    :rules="mandatoryFieldRules"
                  />
                </v-flex>
              </v-layout>
              <v-layout wrap class="ml-3">
                <v-flex xs5>
                  <v-text-field
                    label="Business Unit Number"
                    v-model.number="costcenter"
                    type="number"
                    :disabled="!isNew && !undefinedCostCenter"
                    :rules="mandatoryFieldRules"
                  />
                </v-flex>
              </v-layout>
              <v-layout wrap class="ml-3">
                <v-flex xs5>
                  <v-select
                    v-if="displayJDESelection()"
                    label="JDE Environment"
                    v-model="jde_environment"
                    :items="['Production', 'PY', 'PY & Production']"
                    :disabled="!isAdmin"
                  />
                </v-flex>
              </v-layout>
              <v-layout wrap class="ml-3" v-if="canImportOneMarket">
                <v-flex xs5>
                  <div class="H6-Primary-Left">OneMarket Integration</div>
                  <file-picker
                    accept=".xls, .xlsx"
                    text="Import menu"
                    icon="mdi-upload"
                    base64
                    :loader="menuImportLoader"
                    @onSelect="onOneMarketMenuImport"
                  ></file-picker>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <save-footer
        v-if="isModified"
        :cancelAction="cancel"
        saveLabel="Save Business Unit"
        :saveAction="save"
      />
      <v-dialog v-model="dialog" width="560" @keydown.esc="dialog = false">
        <enable-marketplace
          @dismissDialog="closeDialog"
          @handleMarketplaceStatusChange="handleMarketplaceStatusChange"
          :location.sync="locationDetails"
          serviceType="ENABLE"
        ></enable-marketplace>
      </v-dialog>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import isEqual from 'lodash/isEqual';
import rules from '@/rules';
import filePicker from '@/components/filePicker';
import { featureFlagNames } from '@/constants';
import enableMarketplace from '../marketplace/marketplaceStatusDialog';

export default {
  components: {
    'enable-marketplace': enableMarketplace,
    filePicker,
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isModified) {
      const input = await this.$confirm({
        title: 'Leave without saving?',
        message: 'Changes will be lost if you do not save.',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data: () => ({
    loading: false,
    dialog: false,
    name: '',
    costcenter: '',
    jde_environment: '',
    undefinedCostCenter: false,
    mandatoryFieldRules: [rules.required('Mandatory Field')],
    valid: true,
    backup: {
      name: '',
      costcenter: '',
      jde_environment: '',
    },
    menuImportLoader: false,
    locationDetails: {},
  }),

  watch: {},
  computed: {
    ...mapState('sites', ['siteMap', 'active_site']),
    ...mapGetters('adminPanel', ['isAdmin', 'getStage', 'hasSpecificPermissions']),
    ...mapState('users', ['customPermissions']),
    ...mapGetters('splitio', ['isFeatureOn']),
    isNew() {
      return !this.$route.params?.location_id;
    },
    isModified() {
      const name = isEqual(this.backup.name, this.name);
      const number = isEqual(this.backup.costcenter, this.costcenter);
      const jde_environment = isEqual(this.backup.jde_environment, this.jde_environment);
      return !(name && number && jde_environment);
    },
    isConvertToMarketplaceDisabled() {
      return !this.isAdmin || this.isNew;
    },
    canImportOneMarket() {
      return (
        this.isFeatureOn(featureFlagNames.oneMarketIntegration) &&
        this.active_site?.meta?.partner_menu_import_enabled
      );
    },
  },

  methods: {
    ...mapActions('sites', [
      'getLocationGroup',
      'patchLocationGroup',
      'postLocation',
      'getLocation',
      'patchLocation',
      'getJDEConfig',
    ]),
    ...mapActions('menus', ['uploadOnemarketMenu']),
    closeDialog() {
      this.dialog = false;
    },
    handleMarketplaceStatusChange() {
      this.loading = true;
      this.goToMarketplaceSettings();
    },
    validateForm() {
      return this.$refs.form.validate() && this.name && this.costcenter;
    },
    cancel() {
      this.$refs.form.resetValidation();
      this.goToSite(this.$route.params.site_id);
    },
    displayJDESelection() {
      return this.locationDetails?.address?.country === 'CA' && this.getStage === 'v1';
    },
    async goToSite(id) {
      this.$router.push({
        name: 'site-info',
        params: {
          site_id: id,
        },
      });
    },
    async goToMarketplaceSettings() {
      const location = await this.getLocation({
        id: this.$route.params.location_id,
        nocache: true,
      });
      localStorage.location = JSON.stringify(location);
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 2,
        title: 'Add a New Marketplace',
        last: true,
        to: {
          name: 'marketplace-settings',
          params: {
            site_id: this.$route.params.site_id,
          },
        },
      });
      this.$router.push({
        name: 'marketplace-settings',
        params: {
          site_id: this.$route.params.site_id,
        },
      });
    },
    async save() {
      this.loading = true;
      let error = false;
      if (!this.validateForm()) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }
      if (this.isNew) {
        try {
          const siteInfo = this.siteMap[this.$route.params.site_id];
          const appName = siteInfo.parentMultigroup.name;
          const coordinates =
            this.$route.params.site.locations?.[0]?.address?.coordinates ||
            siteInfo?.address?.coordinates;
          const newLocation = await this.postLocation({
            name: this.name,
            label: {
              en: this.name,
            },
            brands: [],
            address: this.$route.params.site?.address || siteInfo.address,
            latitude: coordinates ? coordinates.latitude : 1,
            longitude: coordinates ? coordinates.longitude : 1,
            meta: {
              unit: this.costcenter,
              unit_id: this.costcenter,
              app_name: appName,
              jde_environment: this.jde_environment ? this.jde_environment : null,
            },
          });
          await this.patchLocationGroup({
            id: this.$route.params.site_id,
            locations: [{ id: newLocation.id }],
          });
        } catch (err) {
          console.error(err);
          error = true;
          this.$toast.error('Could not add Location');
        }
      } else {
        await this.patchLocation({
          id: this.$route.params.location_id,
          label: {
            en: this.name,
          },
          name: this.name,
          meta: {
            unit: this.costcenter,
            unit_id: this.costcenter,
            jde_environment:
              this.jde_environment !== this.backup.jde_environment ? this.jde_environment : null,
          },
        });

        // no need to await since is used just to refresh Back End Cache
        this.getLocation({ id: this.$route.params.location_id, nocache: true });
      }

      if (!error) {
        await this.$store.dispatch('sites/fetchSite', {
          id: this.$route.params.site_id,
          multigroupId: this.$route.params.app,
          fetchWithPermissions: true,
          nocache: 1,
        });
        this.loading = false;
        this.backup.name = this.name;
        this.backup.costcenter = this.costcenter;
        this.backup.jde_environment = this.jde_environment;
        this.$emit('update:location', true);
        this.$toast('Saved successfully');
        this.goToSite(this.$route.params.site_id);
      } else {
        console.error(error);
        this.$toast.error(`Unable to save information.`);
      }
    },
    async onOneMarketMenuImport(fileObj) {
      try {
        fileObj.name = `onemarket/${fileObj.name.replace(/\s+/g, '_')}`;
        const payload = {
          location_group_id: this.$route.params.site_id,
          fileObj,
          bucket_name: 'cdl-partner-menus',
        };

        this.menuImportLoader = true;

        await this.uploadOnemarketMenu(payload);

        this.menuImportLoader = false;
        this.$toast.success('Menu successfully uploaded');
      } catch (err) {
        console.error(err);
        this.menuImportLoader = false;
        this.$toast.error('Something went wrong with the menu upload');
      }
    },
  },
  async mounted() {
    if (this.isNew) {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 2,
        title: 'Add a New Business Unit',
        to: { name: 'location-settings', params: { site_id: this.$route.params.site_id } },
      });
      return;
    }
    this.$store.dispatch('adminPanel/setLoading', true);
    try {
      if (this.$route.params.location_id) {
        // fetch refreshed location nocache
        this.locationDetails = await this.getLocation({
          id: this.$route.params.location_id,
          nocache: true,
        });
        this.name = this.locationDetails.name;
        this.costcenter = this.locationDetails.meta?.unit || '';
        this.backup.name = this.name;
        this.backup.costcenter = this.costcenter;
        this.undefinedCostCenter = this.costcenter === '';

        // populate jde_environment field if it exists
        if (this.costcenter && this.getStage === 'v1') {
          this.jde_environment = await this.getJDEConfig(this.costcenter);
          this.backup.jde_environment = this.jde_environment;
        }
      } else {
        this.$refs.form.reset();
      }
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 2,
        title: 'Configure Business Unit',
        to: {
          name: 'location-settings',
          params: {
            site_id: this.$route.params.site_id,
            location_id: this.$route.params.location_id,
          },
        },
      });
    } catch (err) {
      console.error('could not fetch data', err);
      this.$toast.error('Failed to fetch data, please reload');
    }
    this.$store.dispatch('adminPanel/setLoading', false);
  },
};
</script>

<style></style>
