import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VBtn,
        {
          staticClass: "mt-4",
          attrs: { color: "primary rounded m-2", loading: _vm.loader },
          on: { click: _vm.showFilePicker }
        },
        [
          _c(VIcon, { attrs: { left: "", dark: "" } }, [
            _vm._v(_vm._s(_vm.icon))
          ]),
          _vm._v(_vm._s(_vm.text) + "\n  ")
        ],
        1
      ),
      _c("input", {
        ref: "fileInput",
        attrs: { type: "file", accept: _vm.accept },
        on: { change: _vm.handleFileChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }