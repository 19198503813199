<template>
  <div>
    <v-btn class="mt-4" color="primary rounded m-2" :loading="loader" @click="showFilePicker">
      <v-icon left dark>{{ icon }}</v-icon
      >{{ text }}
    </v-btn>
    <input ref="fileInput" type="file" :accept="accept" @change="handleFileChange" />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
    loader: {},
    base64: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      file: { name: null, content: null },
      input: null,
    };
  },

  methods: {
    showFilePicker() {
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      this.$refs.fileInput.dispatchEvent(event);
    },

    handleFileChange() {
      const that = this;
      const selectedFile = that.$refs.fileInput.files[0];
      that.file = { ...that.file, name: selectedFile?.name };
      const reader = new FileReader();

      if (selectedFile) {
        reader.onload = (e) => {
          if (that._props.base64) {
            const base64Prefix = `data:${selectedFile.type};base64,`;
            const base64Content = e.target.result.replace(base64Prefix, '');
            that.file = { ...that.file, content: base64Content };
          } else {
            that.file = { ...that.file, content: e.target.result };
          }
        };

        if (that._props.base64) {
          reader.readAsDataURL(selectedFile);
        } else {
          reader.readAsText(selectedFile);
        }
      }
    },
  },

  watch: {
    file(file) {
      // Create select event on file change
      if (file.name && file.content) this.$emit('onSelect', file);
    },
  },
};
</script>

<style scoped>
input[type='file'] {
  display: none;
}
</style>
